import moment from "moment";
import { DEFAULT_DATA } from "@/config";

const CardDataMap: any = {
  // 设备仪表盘
  // "device/DashBoard": JSON.stringify({
  //   size: "12x1",
  //   title: "设备统计",
  //   content: [
  //     {
  //       componentName: "Statistic",
  //       colSpan: 12,
  //       title: "今日设备消息量",
  //       valueStyle: { paddingTop: "22px" },
  //       valueConfig: {
  //         type: "http",
  //         url: "/api/dashboard/_multi",
  //         params: {
  //           method: "POST",
  //           data: [
  //             {
  //               dashboard: "device",
  //               object: "message",
  //               measurement: "quantity",
  //               dimension: "agg",
  //               group: "oneday",
  //               params: { time: "1d", format: "yyyy-MM-dd", from: "now-1d" },
  //             },
  //           ],
  //         },
  //         resDataKey: "result.0.data.value",
  //       },
  //     },
  //     {
  //       componentName: "Statistic",
  //       colSpan: 12,
  //       title: "当月设备消息量",
  //       valueStyle: { paddingTop: "22px" },
  //       valueConfig: {
  //         type: "http",
  //         url: "/api/dashboard/_multi",
  //         params: {
  //           method: "POST",
  //           data: [
  //             {
  //               dashboard: "device",
  //               object: "message",
  //               measurement: "quantity",
  //               dimension: "agg",
  //               group: "thisMonth",
  //               params: {
  //                 time: "1M",
  //                 format: "yyyy-MM",
  //                 limit: 1,
  //                 from: "now-1M",
  //               },
  //             },
  //           ],
  //         },
  //         resDataKey: "result.0.data.value",
  //       },
  //     },
  //   ],
  // }),

  // 告警信息 - 告警记录
  "domain/power": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-dian",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
  }),
  "domain/education": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-jiaoyu",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
    btnConf: {},
  }),
  "domain/business": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-qishiyefuwu48",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
    btnConf: {},
  }),
  "topic/smart-office": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-zhinengbangong",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
    btnConf: {},
  }),
  "topic/device": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-shebeiyunwei",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
    btnConf: {},
  }),
  "topic/digital-classroom": JSON.stringify({
    size: "4x2",
    content: [
      {
        componentName: "Image",
        colSpan: 24,
        iconName: "iconcolor-shuzixuetang1",
        wrapStyle: { paddingTop: "14px" },
      },
    ],
    btnConf: {},
  }),

  // "open/app": JSON.stringify({
  //   size: "6x1",
  //   content: [
  //     {
  //       componentName: "Statistic",
  //       colSpan: 24,
  //       title: "应用数量",
  //       valueStyle: { paddingTop: "22px" },
  //       valueConfig: {
  //         type: "http",
  //         url: "/api/device/product/count",
  //         params: {
  //           method: "GET",
  //           data: {},
  //         },
  //         resDataKey: "result.total",
  //       },
  //     },
  //   ],
  //   btnConf: {
  //     add: {
  //       mutualType: "drawer",
  //       data: {},
  //       model: "add",
  //       // width: 130,
  //     },
  //     view: {
  //       mutualType: "drawer",
  //       data: {},
  //       model: "view",
  //       // width: 130,
  //     },
  //   },
  // }),

  // 通知管理 - 通知配置
  // "notice/Config": JSON.stringify({
  //   size: "4x2",
  //   content: [
  //     {
  //       componentName: "Image",
  //       colSpan: 24,
  //       iconName: "iconcolor-xitongpeizhibeifen",
  //       wrapStyle: { paddingTop: "14px" },
  //     },
  //   ],
  // }),
};

export default CardDataMap;

import "./public-path";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { registerMicroApps, start, setDefaultMountApp } from "qiankun";
// import * as Sentry from '@sentry/react';

import Main from "./components/Main";
import { getMicroApps, getSubAppUrl } from "@/config/microApp";

// import "@/utils/rem";

import "@/assets/style/index.less";
// iconfont-Symbol模式需要
import "@/assets/fonts/iconfontColor/iconfont";

// redux
import store from "./stores";
import { loginUrl } from "./utils/base";

// router
// import { rootRoutes, renderRoutes } from "@/routes";

console.log("getMicroApps()", getMicroApps());

registerMicroApps(getMicroApps());

start({
  sandbox: {
    // 启用样式隔离
    experimentalStyleIsolation: true,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("main-root") as HTMLElement
);

// Sentry.init({
//   dsn: "https://721b7a72eeac0c15ee3f54c3cc117fee@o4505679510568960.ingest.sentry.io/4505680711188480",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["http://dev.iot.kaihong.com/api/"],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

root.render(
  <Provider store={store}>
    <ConfigProvider>
      {/* <App style={{height: '100%'}}> */}
      <Suspense>
        <BrowserRouter>
          <Main />
        </BrowserRouter>
      </Suspense>
      {/* </App> */}
    </ConfigProvider>
  </Provider>
);

import { useState, useEffect } from "react";
import { Collapse, Row, Col } from "antd";
import CardDataMap from "@/components/Menu/Config/cardDataMap";
import ProCard from "./ProCard";

import Service from "./service";

import "./index.less";

const { Panel } = Collapse;

const TWBaseMenuPrefixCls = "tw-base-menu";

export const service = new Service();

type MenuProps = {
  menuData?: any[];
  onEntryClick?: any;
};

/**
 * 处理menu-item的数据
 */
const processMenuItem = (item: any) => {
  if (!item.cardData && CardDataMap[item.code]) {
    item.cardData = CardDataMap[item.code];
  }
  // size: 12x1 | 6x1 | 4x2, 无配置默认给4x2
  let size = "4x2";
  if (item.cardData) {
    size = item.cardData.match(/\dx\d/) && item.cardData.match(/\dx\d/)[0];
  }
  item.size = size;
};

const Menu = (props: MenuProps) => {
  const [menuList, setMenuList] = useState<any[]>([]);
  const [menuOpen, setMenuOpen] = useState(true);
  const { menuData = [], onEntryClick } = props;
  const handleEntry = () => {
    setMenuOpen(!menuOpen);
    onEntryClick(!menuOpen);
  };
  const getMenuData = () => {
    const menuList = menuData.map((item: any) => {
      processMenuItem(item);
      const itemData =
        item.children?.map((ele: any) => {
          processMenuItem(ele);
          return { ...ele };
        }) || [];
      return { ...item, children: itemData };
    });
    setMenuList(menuList);
  };
  useEffect(() => {
    const antLayout = document.querySelector(".ant-layout");
    const layoutClassList = antLayout?.classList;
    if (!layoutClassList?.contains("ant-layout-has-sider")) {
      layoutClassList?.add("ant-layout-has-sider");
    }
  }, []);

  useEffect(() => {
    if (menuData.length === 0) {
      return;
    }
    getMenuData();
  }, [menuData]);

  // 卡片选中相关
  const [activeCode, setActiveCode] = useState("");
  const [withoutCollapse, setWithoutCollapse] = useState(true);
  const onActiveChange = (code: string) => {
    if (code !== activeCode) {
      setActiveCode(code);
    }
  };

  useEffect(() => {
    if (menuList.length > 0) {
      let activeCode = "";
      const href = location.href;
      for (const item of menuList) {
        if (item.children && item.children.length > 0) {
          for (const itemChildren of item.children) {
            if (href.includes(itemChildren.url)) {
              activeCode = itemChildren.code;
              setWithoutCollapse(false);
              break;
            }
          }
        } else if (href.includes(item.url)) {
          activeCode = item.code;
          setWithoutCollapse(true);
          break;
        }
      }
      setActiveCode(activeCode);
    }
  }, [menuList]);

  return (
    <>
      <div
        className={`${TWBaseMenuPrefixCls}-side ${menuOpen ? "menu-open" : ""}`}
      >
        <div className={`${TWBaseMenuPrefixCls}_title ${withoutCollapse ? 'without-collapse': ''}`}>工作台</div>
        <div className={`${TWBaseMenuPrefixCls}_content`}>
          <Row gutter={[16, 16]}>
            {menuList.map((item: any, index: number) => {
              // 包含menu
              if (item.children && item.children.length > 0) {
                return (
                  <Col span={24} key={item.id || index}>
                    {/* 工作台卡片折叠面板默认展开 */}
                    <Collapse
                      className={`${TWBaseMenuPrefixCls}_collapse`}
                      defaultActiveKey={[item.id]}
                    >
                      <Panel
                        header={item?.showName || item?.name}
                        key={item.id}
                      >
                        <Row gutter={[16, 16]} className="menu-card-wrap">
                          {item?.children?.map((ele: any) => {
                            return (
                              <ProCard
                                key={ele.code}
                                active={activeCode === ele.code}
                                onActiveChange={onActiveChange}
                                {...ele}
                              />
                            );
                          })}
                        </Row>
                      </Panel>
                    </Collapse>
                  </Col>
                );
              } else {
                return (
                  <ProCard
                    key={item.code}
                    active={activeCode === item.code}
                    onActiveChange={onActiveChange}
                    {...item}
                  />
                );
              }
            })}
          </Row>
          <div style={{ width: "100%", height: 135 }}></div>
        </div>
        <div className={`${TWBaseMenuPrefixCls}_mask`}></div>
      </div>
      <div
        className={`${TWBaseMenuPrefixCls}-entry`}
        onClick={() => handleEntry()}
      />
    </>
  );
};

export default Menu;

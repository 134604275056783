import http from "./http";
import { BASE_SYSTEM_ENUM } from "@/utils/base";

// 获取用户菜单
const baseUserOwnTree = (params: any = {}) =>
  http.post(`/${BASE_SYSTEM_ENUM.API_BASE}/menu/user-own/tree`, params);
// 获取用户基本信息
const baseAutorizeMe = (params: any = {}) =>
  http.get(`/${BASE_SYSTEM_ENUM.API_BASE}/authorize/me`, { params });
// 获取系统基本信息
const baseSystemConfig = (params: any = {}) =>
  http.get(`/${BASE_SYSTEM_ENUM.API_BASE}/system/config/front`, { params });
// 获取通知数据
const baseNotices = (params: any = {}) =>
  http.get(`/${BASE_SYSTEM_ENUM.API_BASE}/notifications/_query`, { params });
// 清除通知
const baseClearNotices = (params: any = {}) =>
  http.post(`/${BASE_SYSTEM_ENUM.API_BASE}/notifications/_read`, params);
// 改变通知状态
const baseChangeNoticeReadState = (params: any = {}) =>
  http.get(`/${BASE_SYSTEM_ENUM.API_BASE}/notifications/${params.id}/read`);
// 退出登录
const baseLogout = () =>
  http.get(`/${BASE_SYSTEM_ENUM.API_BASE}/user-token/reset`);

const getAppList = (params: Record<string, unknown>) =>
  http
    .post("/api/isv/app/store/page", params)
    .then((res: any) => (res.code === 200 ? res.data.list : []))
    .then((list) =>
      list.map((item: any) => ({
        id: item.id,
        name: item.name,
        logo: item.resources.find((res: any) => res.type === "ICON")?.url,
        domain: item.domain,
        topic: item.topic,
        type: item.type,
        distributionStatus: item.distributionStatus,
        subType: item.subType,
      }))
    ); // 获取应用列表

export default {
  getAppList,
  baseUserOwnTree,
  baseAutorizeMe,
  baseSystemConfig,
  baseNotices,
  baseClearNotices,
  baseChangeNoticeReadState,
  baseLogout,
};

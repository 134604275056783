import { Col, Row, Card, Button } from "antd";

// card-content
import { COMPONENT_MAP } from "@/components/Menu/Config/cardContent";

import { useNavigate } from "react-router-dom";

import { getSubAppUrl } from "@/config/microApp";
import actions from "@/stores/globalstate";

import "./index.less";

interface CardProps {
  name?: string;
  url?: string;
  code: string;
  id?: string;
  cardData?: string;
  buttons?: any[];
  from?: string; // workspace | cardLibrary
  size?: string;
  active?: boolean; // 当前卡片是否激活
  onActiveChange?: any;
}

type CardDataType = {
  size?: string;
  title?: string;
  content?: any[];
  colSpan?: string | number;
  btnConf?: any;
};

const TWBaseMenuProCardPrefixCls = "tw-base-menu-pro-card";

const ProCard = (props: CardProps) => {
  const navigate = useNavigate();

  const { name, url, cardData, size: sizeProp, active = false, code, onActiveChange } = props;

  const headStyle: any = {
    borderBottom: "none",
    padding: "0 16px",
    minHeight: "42px",
    fontSize: "18px",
  };
  const bodyStyle = {
    padding: "0",
  };

  let cardDataObject: CardDataType = {};
  if (cardData) {
    cardDataObject = JSON.parse(cardData);
  }

  const {
    size = sizeProp,
    title = name,
    content = [],
    btnConf = null,
  } = cardDataObject;

  const addProps = btnConf?.add;
  const viewProps = btnConf?.view;
  const handleBtnClick = (e: any, type = "add") => {
    e.stopPropagation();
    if (type === "add") {
      actions.setGlobalState({
        cardBtnClick: {
          type,
          url,
          timestamp: +new Date(),
        },
      });
    } else if (type === "view") {
      const truthUrl = url && getSubAppUrl(url);
      if (truthUrl) {
        if (location.pathname.includes(truthUrl)) {
          actions.setGlobalState({
            cardBtnClick: {
              type,
              url,
              timestamp: +new Date(),
            },
          });
        } else {
          navigate(truthUrl);
        }
      }
    }
    onActiveChange(code);
  };

  const handleCardClick = () => {
    if (!addProps && !viewProps && url) {
      const truthUrl = getSubAppUrl(url);
      if (truthUrl) {
        navigate(truthUrl);
      }
      onActiveChange(code);
    }
  };

  const [w, h] = (size || "4x2").split("x");

  if (size === "4x2") {
    // 一行三个的卡片
    headStyle.fontSize = "14px";
  }

  return (
    <Col span={Number(w) * 2}>
      <Card
        className={`${TWBaseMenuProCardPrefixCls} h-type-${h}` + (active ? ' active': '')}
        title={title}
        headStyle={headStyle}
        bodyStyle={bodyStyle}
        onClick={handleCardClick}
      >
        <Row align="middle" justify="center">
          {content.map((item, key) => {
            return (
              <Col
                span={item.colSpan || 24}
                className={`${TWBaseMenuProCardPrefixCls}-col`}
                key={key}
              >
                {(COMPONENT_MAP[item.componentName] &&
                  COMPONENT_MAP[item.componentName]({
                    ...item,
                  })) ||
                  COMPONENT_MAP["Default"]()}
              </Col>
            );
          })}
        </Row>
        {/* 底部区域 */}
        <div
          className={`${TWBaseMenuProCardPrefixCls}-footer`}
          // 有特性卡片时调整样式
          style={{
            marginTop: content?.filter(
              (item) => item.componentName === "Features"
            )?.length
              ? 0
              : "10px",
          }}
        >
          {addProps && (
            <Button
              onClick={(e) => handleBtnClick(e, "add")}
              key="addButton"
              type="primary"
              style={{ borderRadius: 0, width: addProps.width || 68 }}
            >
              {"新增"}
            </Button>
          )}
          {viewProps && (
            <Button
              onClick={(e) => handleBtnClick(e, "view")}
              key="viewButton"
              style={{
                borderRadius: 0,
                width: viewProps.width || 68,
                marginLeft: 12,
              }}
            >
              {"查看"}
            </Button>
          )}
        </div>
      </Card>
    </Col>
  );
};

export default ProCard;

import API from "@/api";
import { useRequest } from "ahooks";
import { Dropdown, Input, MenuProps } from "antd";
import React, { useMemo, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const AppSelect = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const { data: apps = [], run } = useRequest(() => API.getAppList({ name }), {
    refreshDeps: [name],
    debounceWait: 500,
  });

  const items = useMemo(() => {
    return apps.map((app: any) => ({
      label: app.name,
      key: app.id,
    }));
  }, [apps]);

  const handleClick: MenuProps["onClick"] = ({ key }) => {
    // const app = apps.find((app: any) => app.id === key);
    // console.log(app, "app");
    navigate(`/app/app-market/app/${key}`);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Dropdown menu={{ items, onClick: handleClick }} placement="bottomLeft">
        <Input
          placeholder="请输入原子化服务/应用的名称"
          addonBefore={<SearchOutlined />}
          allowClear
          value={name}
          style={{ width: 320 }}
          onChange={(e) => setName(e.target.value)}
        />
      </Dropdown>
    </div>
  );
};

export default AppSelect;
